
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModalCloseBtn from '@/components/shared/modal/TmModalCloseBtn.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    TmModalCloseBtn,
    TmModal,
    TmButton,
  },
  props: {
    initValue: {
      type: String,
    },
    modalTitle: {
      type: String,
      default: 'Add contact note',
    },
    btnText: {
      type: String,
      default: 'Add note',
    },
  },
  setup(props) {
    const value = ref(props.initValue)

    const btnsNoteList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.assignment,
      editorBtns.tags,
      editorBtns.mention,
    ]

    return {
      value,
      btnsNoteList,
    }
  },
})
