import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d45e538"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "note-modal__header" }
const _hoisted_2 = { class: "page-title-20 emphasize--text lh-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_modal_close_btn = _resolveComponent("tm-modal-close-btn")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "note",
    "no-footer": "",
    "no-header": "",
    "without-content-padding": ""
  }, {
    "modal-content": _withCtx(({ hideModal }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.modalTitle), 1),
        _createVNode(_component_tm_modal_close_btn)
      ]),
      _createVNode(_component_tm_field, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: "editor",
        autofocus: "",
        "note-state": "",
        buttons: _ctx.btnsNoteList,
        class: "note-editor",
        outline: false,
        "box-shadow": false,
        "min-height": "204px"
      }, {
        "send-button": _withCtx(() => [
          _createVNode(_component_tm_button, {
            size: "large",
            color: "orange500",
            onClick: hideModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.btnText), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["modelValue", "buttons"])
    ]),
    _: 1
  }))
}